function HeroImgAboutCompoent() {
  return (
    <>
      <div className="HeroImgAbout">
        <div className="imgfilterabout">
          <h1 className="heroheaderabout">Stora Kungsladugården</h1>
          <p className="herotextheaderabout">Vi är kunniga inom växtodling</p>
        </div>
      </div>
    </>
  );
}

export default HeroImgAboutCompoent;
