import Video from "../video/flowers.mp4";
import React from "react";
function HeroImgFlowerComponent() {
  return (
    <>
      <video src={Video} autoPlay loop muted />
      <div className="contanierVideotext">
        <div className="HeroFilterFlower">
          <h1 className="HeroHeaderFlower">Storakungensblommor</h1>
          <h2 className="HeroHeaderFlower2">Självplock blommor</h2>
        </div>
      </div>
    </>
  );
}

export default HeroImgFlowerComponent;
