import "../Foldercss/kontakt.css";
function KontaktPage() {
  return (
    <>
      {/*Hero bild på Kontaktsidan*/}
      <section className="kontaktimg">
        <div className="imgkontaktfilter">
          <h1 className="imgkontaktheader">Kontakt</h1>
        </div>
      </section>

      {/*Text om kontaktinformation*/}
      <article className="kontakt-flex">
        <div className="kontaktcard">
          <h2 className="headerkontakt">Kontakt</h2>
          <div className="epostcontainer">
            <div className="eposticon">
              <i class="bi bi-envelope"></i>
            </div>
            <div className="epost">Epost</div>
          </div>
          <p className="textepost">storakungsladugarden@gmail.com</p>
        </div>
      </article>
    </>
  );
}

export default KontaktPage;
