
function TextAboutComponent (props){
    return(
        <>
        <article className="textaboutflex">
          <div className="textaboutwrapp">
            <div className="headerAbout">{props.headerabout}</div>
            <p className="textAbout">{props.textabout} </p>
            <p className="textAbout">{props.textaboutrowtwo}</p>
            <p className="textAbout">{props.textaboutrowthree}</p>
        </div>
        </article>
        </>
    ) 
}

export default TextAboutComponent; 