function ListComponent(props) {
  return (
    <>
      <ul>
        <li>
          <h3 className="headerlist">{props.listheader}</h3>
        </li>
        <li className="list">{props.one}</li>
        <li className="list">{props.two}</li>
        <li className="list">{props.three}</li>
        <li className="list">{props.four}</li>
      </ul>
    </>
  );
}

export default ListComponent;
