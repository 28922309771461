function ImgAboutComponent(props) {
  return (
    <>
      <div className="imgaboutcontainer">
        <div className="imgaboutflex">
          <img className="imgabout" src={props.imgabout} alt="" />
          <img className="imgabout" src={props.imgabout2} alt="" />
        </div>
      </div>
    </>
  );
}

export default ImgAboutComponent;
