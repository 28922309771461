function InfoTextComponent() {
  return (
    <>
      <div className="card2">
        <div className="infowrapp">
          <div className="rubrikcard">Välkommen till Stora Kungsladugården</div>
          <p className="textcard">
            Stora Kungsladugården ligger strax utanför Nyköping och ägs av
            Statens Fastighetsverk. Historiskt har gården haft arrendatorer
            sedan 1800-talet och vi tog över arendet 2009.
          </p>
          <p className="textcard">
            På gården bedrivs idag växtodling, självplockning av blommor och
            uthyrning av bostäder och lokaler.
          </p>
          <p className="textcard">
            Vårt mål med verksamheten är att alltid utvecklas och förbättras. Vi
            jobbar mot att få höga skördar med noggrant arbete och höga
            insatser.
          </p>
        </div>
      </div>
    </>
  );
}

export default InfoTextComponent;
