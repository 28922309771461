import "../Foldercss/home.css";
import HeroImgHome from "../Components/HeroImgHomeComponent";
import HeaderComponent from "../Components/HeaderComponent";
import InfoTextComponent from "../Components/InfoTextComponent";
import ShortSectionComponent from "../Components/ShortSectionComponent";
import ImgAndTextOneComponent from "../Components/ImgAndTextOneComponent";
import TextAbout from "../Components/TextAboutComponent";

function homepage() {
  return (
    <>
      <HeroImgHome />
      <HeaderComponent header={"Stora Kungsladugården"} />

      {/* Section ett börjar här */}

      {/* Sektion med korta stycken börjar här */}
      <div className="flexShort">
        <div className="shortContainer">
          <ShortSectionComponent
            headershort={"Växtodling"}
            shorttext={
              "På totalt ca 800 hektar mark fördelat på två gårdar bedrivs växtodling av höstvete, höstraps och gräsfrö. "
            }
            link={"/About"}
          />
        </div>
        <div className="shortContainer">
          <ShortSectionComponent
            headershort={"Självplock blommor"}
            shorttext={
              "Vi har under sommaren självplock av blommor på fältet utanför Max och Mcdonalds i Nyköping."
            }
            link={"/Blommor"}
          />
        </div>
        <div className="shortContainer">
          <ShortSectionComponent
            headershort={"Hyra bostad"}
            shorttext={
              "På Stora Kungsladugården finns det nio hyresbostäder. Du bor i lantlig miljö men har nära till stan."
            }
            link={"/Rent"}
          />
        </div>
      </div>

      {/* Välkommen till gården bild till höger */}
      <article className="section4">
        <InfoTextComponent />
        <img className="imgsection" src="./img/vete4.jpg" alt="vete" />
      </article>

      {/* Välkommen till gården bild till vänster */}
      <article className="section5">
        <img className="imgsection" src="./img/redhouse.jpg" alt="rötthus" />
        <ImgAndTextOneComponent />
      </article>

      {/* Text om gården*/}
      <TextAbout
        headerabout={"Gårdens historia"}
        textabout={
          "Stora Kungsladugården är en historisk gård som en gång i tiden var hovleverantör av spannmål och andra råvaror till Kungen som bodde i Nyköpings hus. På gården fanns det kor, får, getter, tyska höns, spanska ankor, påfåglar och kalkoniska höns."
        }
        textaboutrowtwo={
          "1665 brann Nyköpingshus ner och hovet flyttade till Stockholm. I samband med det upphörde Stora kungsladugården att vara hovleverantör av spannmål och andra varor till slottet. Från början av 1800- talet har gården drivits av arrendatorer."
        }
      />

      {/* Bild på Zorro*/}
      <div className="catcontainer">
        <img className="catimg" src="./img/zorro.jpg" alt="katten zorro" />
      </div>

      <TextAbout
        headerabout={"Gårdskatten Zorro"}
        textabout={
          "Zorro är två år gammal och gårdskatt. Han tycker om att var ute och jaga på natten och sova på dagen. Hans favoritdryck är mjölk med tre procent och hans favoriträtt är leverpastej."
        }
        textaboutrowtwo={""}
      />
    </>
  );
}

export default homepage;
