function FlowerGridContainer() {
  return (
    <>
      <div className="flexcontainerflower">
        <img className="FlowerImg" src="./img/Blomma2.JPG" alt="Blommor" />
        <img className="FlowerImg" src="./img/Blommor1.JPG" alt="Blommor" />
        <img className="FlowerImg" src="./img/Blomma1.JPG" alt="Blommor" />
      </div>

      <div className="gridcontainerflower2">
        <div className="vastextcontainer">
          <img
            className="FlowerImgvas"
            src="./img/vas.JPG"
            alt="Blommor i en vas"
          />

          <div className="textBild">
            <h2 className="head">Buketter</h2>
            <p className="flowertext">
              Plockade blommor ger oss möjlighet att skapa vackra buketter och
              dekorera våra hem eller ge bort som gåvor till nära och kära. Att
              ha färska blommor i hemmet kan förhöja stämningen och skapa en mer
              trivsam och inbjudande atmosfär.{" "}
            </p>
          </div>
          <div className="textbild">
            <h2 className="head">Parkering</h2>
            <p className="flowertext">
              Det går att parkera bilen nere på fältet. Du svänger ner vid
              max-korsningen Bergshammarvägen / Gumsbackevägen.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlowerGridContainer;
