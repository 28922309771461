import "../Foldercss/rent.css";
import ListComponent from "../Components/ListComponent";
import RentCardComponent from "../Components/RentCardComponent";
import HeadTextLComponent from "../Components/HeaderTextLComponent";
import HeroImgRentComponenet from "../Components/HeroImgRentComponenet";

function rentpage() {
  return (
    <>
      <HeroImgRentComponenet />
      <div className="HeaderTextLFlex">
        <HeadTextLComponent />
      </div>

      <div className="apartmentheader">Hyresbostäder</div>

      {/*Hyresbostäder*/}
      <div className="flexRentCard">
        {/* Långholmen */}
        <div className="cardcontainer">
          <RentCardComponent
            imgrent={"./img/langholmen.jpg"}
            namebuilding={"Långholmen"}
            status={"Uthyrd"}
            area={"60 m2"}
            room={"4 lägenheter"}
            textrent={
              " Hus på landet med fyra lägenheter. Nära Nyköping. Kallhyra, luftvärmepump som även har ac funktion. Möjlighet att elda i kökspanna. Parkering ingår."
            }
            item1={"Tvättmaskin"}
            item2={"Diskmaskin"}
            item3={"Kyl"}
            item4={"Frys"}
          />
        </div>

        {/* Rättarbostaden */}
        <div className="cardcontainer">
          <RentCardComponent
            imgrent={"./img/rattarbostaden.jpg"}
            namebuilding={"Rättarbostaden"}
            status={"Uthyrd"}
            area={"80 m2"}
            room={"4 rum"}
            textrent={
              "Hus på landet nära Nyköping 5 rum. Kallhyra, luftvärmepump som även har ac funktion möjlighet att elda i kökspanna. Parkering ingår."
            }
            item1={"Tvättmaskin"}
            item2={"Diskmaskin"}
            item3={"Kyl"}
            item4={"Frys"}
          />
        </div>

        {/* Stora Arbetarbostaden*/}
        <div className="cardcontainer">
          <RentCardComponent
            imgrent={"./img/storaarbetarbostaden.jpg"}
            namebuilding={"Stora Arbetarbostaden"}
            status={"Uthyrd"}
            area={"60 - 90 m2"}
            room={"3 lägenheter"}
            textrent={
              "Två lägenheter på nedervåningen och en på övervåningne. Ligger på landet nära Nyköping. Kallhyra, luftvärmepump som även har ac funktion. Parkering ingår."
            }
            item1={"Tvättmaskin"}
            item2={"Diskmaskin"}
            item3={"Kyl"}
            item4={"Frys"}
          />
        </div>

        {/* Östraflygeln*/}
        <div className="cardcontainer">
          <RentCardComponent
            imgrent={"./img/mangardsbyggnad.jpg"}
            namebuilding={"Östra flygeln"}
            status={"Uthyrd"}
            area={"110 m2"}
            room={"5 rum"}
            textrent={
              "Hus på landet nära Nyköping med trädgård. Kallhyra, luftvärmepump som även har ac funktion. Parkering ingår."
            }
            item1={"Tvättmaskin"}
            item2={"Diskmaskin"}
            item3={"Kyl"}
            item4={"Frys"}
          />
        </div>

        {/*Kuskbostaden*/}
        <div className="cardcontainer">
          <RentCardComponent
            imgrent={"./img/kuskbostaden.jpg"}
            namebuilding={"Kuskbostaden"}
            status={"Uthyrd"}
            area={"45 m2"}
            room={"1 rum"}
            textrent={
              "Mindre hus på landet nära Nyköping. Kallhyra luftvärmepump som även har ac funktion. Parkering ingår."
            }
            item1={"Tvättmaskin"}
            item3={"Kyl"}
            item4={"Frys"}
          />
        </div>
      </div>
      {/* Listor börjar här  */}

      <div className="listflex">
        <div className="listwrapp">
          <ListComponent
            listheader={"Nyköpings centrum"}
            one={"Buss 13 minuter Buss 563 (busshållplats finns vid gården)"}
            two={"Bil 5 minuter"}
            three={"Cykel 10 minuter"}
            four={"Gå 29 minuter"}
          />
        </div>
        <div className="listwrapp">
          <ListComponent
            listheader={"I närheten"}
            one={"Willys 700m"}
            two={"Max 850 m"}
            three={"Mcdonalds 1km"}
            four={"Filmstaden 1.6km"}
          />
        </div>
      </div>
    </>
  );
}

export default rentpage;
