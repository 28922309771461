function HeadTextLComponent() {
  return (
    <>
      <div className="htwrapp">
        <div className="headerL">Om gården</div>
        <p className="breadtextL">
          {" "}
          Stora Kungsladugården ligger strax utanför Nyköping. Det finns totalt
          nio hyresbostäder på gården, två av dessa är hus och resterande är
          lägenheter. Alla bostäder har kallhyra, det finns fiber indraget och
          gratis parkering. 
        </p>
        <p className="breadtextL">Det finns goda kommunikationer för att ta sig till
          Nyköpings centrum. Närmaste mataffär är Willys. För den som tycker om
          att vandra så ligger Sörmlandsleden nära gården.{" "}</p>
        <p className="breadtextL">
          Intresserad av att hyra bostad, skicka ett mejl till storakungsladugarden@gmail.com med
          information om dig själv och vilken bostad du vill hyra.
        </p>
      </div>
    </>
  );
}

export default HeadTextLComponent;
