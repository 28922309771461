
function RentCardComponent (props) {
    return (
        <>
        <div className="rentcard">
        <div className="contentrentcontainer">
        <img className="imgrentcard" src={props.imgrent} alt="" />
            <div className="rentContent">
                <div className="headerRent"> {props.namebuilding} </div>
            <div className="textgroup1"> 
            <p className="textgroupitem1">{props.status}</p>
            <p className="textgroupitem2">{props.area}</p>
            <p className="textgroupitem3">{props.room}</p>
            </div>
            <p className="textrent">
            {props.textrent}
            </p>
            <div className="textgroup2">
            <p className="textgroupitem4"> {props.item1}</p>
            <p className="textgroupitem5"> {props.item2} </p>
            <p className="textgroupitem6"> {props.item3}</p>
            <p className="textgroupitem7"> {props.item4}</p>
            </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default RentCardComponent; 