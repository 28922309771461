import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/home";
import Rent from "./Pages/rentpage";
import About from "./Pages/about";
import Error from "./Pages/ErrorPage";
import Blommor from "./Pages/blommor";
import Kontakt from "./Pages/kontakt";
import Footer from "./Components/FooterComponent";
import { useEffect, useState } from "react";
function App() {
  const [toggle, setToggle] = useState(false);

  const [hide, setHide] = useState(window.innerWidth);

  const hidemenu = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const changewidth = () => {
      setHide(window.innerWidth);
    };

    window.addEventListener("resize", changewidth);

    return () => {
      window.removeEventListener("resize", changewidth);
    };
  }, [toggle]);

  return (
    <>
      <div className="navbarcontainer">
        <div className="loggacontainer">
          <Link to={"/"}>
            <img className="logga" src="./img/logga.png" alt="logga" />
          </Link>
        </div>
        {(toggle || hide > 820) && (
          <nav>
            <ul>
              <li>
                <Link id="navlinkstyle" onClick={hidemenu} to={"/"}>
                  Hem
                </Link>
              </li>
              <li>
                <Link id="navlinkstyle" onClick={hidemenu} to={"/About"}>
                  Om företaget
                </Link>
              </li>
              <li>
                <Link id="navlinkstyle" onClick={hidemenu} to={"/Rent"}>
                  Hyra bostad
                </Link>
              </li>
              <li>
                <Link id="navlinkstyle" onClick={hidemenu} to={"/Blommor"}>
                  Självplock blommor
                </Link>
              </li>
              <li>
                <Link id="navlinkstyle" onClick={hidemenu} to={"/Kontakt"}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </nav>
        )}
        <div className="menubutton" onClick={hidemenu}>
          <i class="bi bi-list"></i>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Rent" element={<Rent />} />
        <Route path="/About" element={<About />} />
        <Route path="/Blommor" element={<Blommor />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
