
function HeroImgHome () {
    return (
        <>
        <div className="heroimg imgfilter">
            <div className="filterheroimg">
            <h1 className="herohaeder">Stora Kungsladugården</h1>
            <p className="herotext">En gård utanför Nyköping i Södermanland</p>
            </div>
        </div>
        </>
    )
    
}

export default HeroImgHome;