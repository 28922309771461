
function HeroImgRentComponenet () {
    return(
        <>
        <div className="HeroimgRent">
            <div className="Herorentfilter">
            <h1 className="HeroHeaderRent">Hyra bostad</h1>
            </div>
        </div>
        </>
    )
}

export default HeroImgRentComponenet; 
