import "../Foldercss/about.css";
import HeroImgAbout from "../Components/HeroImgAboutCompoent";
import TextAbout from "../Components/TextAboutComponent";
import ImgAboutComponent from "../Components/ImgAboutComponent";

function AboutPage() {
  return (
    <>
      <HeroImgAbout />

      <h2 className="headerabout">Om företaget</h2>

      {/* Bild på tröska */}
      <div className="containerharvest">
        <img className="imgaboutharvest" src="./img/harvester.jpg" alt="" />
      </div>

      {/* Text om verksamheten */}
      <TextAbout
        headerabout={"Verksamheten"}
        textabout={
          "Företaget är verksamt på två gårdar, Stora Kungsladugården en statlig arrendegård som förvaltas av SFV (Statens Fastighetsverk) utanför Nyköping och Berga-Tuna Gård i Jönåker privatägd arrendegård."
        }
        textaboutrowtwo={
          "Den dagliga arbetsplatsen är Berga-Tuna Gård där personalutrymmen finns samt företagets verkstad med riklig utrustning."
        }
        textaboutrowthree={
          "Företaget har två fast anställda Anders och Stefan som är företagets stabila hörnstolpar. Därutöver byggnadsarbetare, säsongspersonal samt praktikanter. Under högsäsong kan det vara upp till tio personer igång."
        }
      />

      {/* Bilder på kor och fält */}
      <ImgAboutComponent
        imgabout={"./img/kor.jpg"}
        imgabout2={"./img/vete5.jpg"}
      />

      {/* Text om Lantbruk */}
      <TextAbout
        headerabout={"Lantbruket"}
        textabout={
          "Lantbruket på Stora Kungsladugården är inriktat på intensiv och lönsam växtodling som huvudnäring. Växtodlingen omfattar ca 800 hektar där vi odlar höstvete och höstraps som de stora grödorna. Frövall av sorten ängssvingel på ca 30 hektar som torkas och lagras på gården."
        }
        textaboutrowtwo={
          "Spannmål och raps torkas och lagras på gården och kan säljas under höst vinter och vårsäsongen. Totalt kan ca 5000 ton spannmål lagras."
        }
        textaboutrowthree={
          "Huvudsakligen sker jordbruket med plöjningsfri odling men plöjning sker vid behov. Höstsådda grödor dominerar arealen och maskinkapaciteten är anpassad efter detta"
        }
      />
    </>
  );
}

export default AboutPage;
