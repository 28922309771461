
function ErrorPage () {

    return(
        <>
        <div className="errorheader"></div>
        <div className="error-flex">
            <div className="errorcontainer">
            <div className="errotext">Oj nu blev det något fel, tryck på knappen för att komma till startsidan</div>
            <a href="/"><button className="error-button">Startsidan</button></a> 
            </div>
        </div>
        </>
    )
}

export default ErrorPage;


