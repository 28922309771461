import '../Foldercss/blommor.css'
import HeroImgFlowerComponent from '../Components/HeroImgFlowerComponent';
import FlowerTextComponent from '../Components/FlowerTextComponent';
import FlowerGridContainer from '../Components/FlowerGridComponent';
function blommor () {
    return (
        <>
        <HeroImgFlowerComponent />
        <FlowerTextComponent />
        <FlowerGridContainer />
        </>
    )
}

export default blommor; 