function ImgandTextOneComponent() {
  return (
    <>
      <div className="card1">
        <div className="infowrapp">
          <div className="rubrikcard">Vår historia </div>
          <p className="text1">
            {" "}
            Sedan 2009 har vi arrenderat Stora kungsladugården. Under den tiden
            har vi utvecklat och förbättrat verksamheten med att bygga en ny
            maskinhall, större lagring för spannmål och en ny elevatorgrop.
          </p>
          <p className="text2">
            2011 tog vi över arrendet för Berga-Tuna Gård som ligger utanför
            Jönåker. Här brukar vi marken och sköter om ca 90 köttkor.
          </p>
          <p className="text2">
            Idag brukar vi totalt ca 800 hektar mark och har en
            lagringskapacitet på ca 5000 ton spannmål. Vi arbetar med målet att
            få så höga skördar som möjligt.
          </p>
        </div>
      </div>
    </>
  );
}

export default ImgandTextOneComponent;
