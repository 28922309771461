function FooterComponent() {
  return (
    <>
      <footer className="footer">
        <p className="footerText">Storakungsladugården 2023</p>
        <div className="iconcontainer">
          <a
            href="https://www.facebook.com/profile.php?id=100070831522770"
            className="linksocial"
          >
            {" "}
            <i className="bi bi-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/storakungensblommor/"
            className="linksocial"
          >
            <i class="bi bi-instagram"></i>
          </a>
        </div>
      </footer>
    </>
  );
}

export default FooterComponent;
