import {Link} from 'react-router-dom'
function ShortSectionComponent (props){
    return(
        <>
        <div className="header">{props.headershort}</div>
        <p className="shorttext">{props.shorttext}</p>

        <button className="buttonmore"><Link to={props.link}>Läs mer
        </Link>
        </button>
        </>
    )
}

export default ShortSectionComponent;