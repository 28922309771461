function FlowerTextComponent() {
  return (
    <>
      <div className="flowerwrapp">
        <div className="flowercontainer">
          <div className="headerflower">Självplock blommor</div>
          <p className="textflower">
            Sedan 2020 har vi haft självplock av blommor på Stora
            Kungsladugården mittemot Max och Mcdonalds i Nyköping. En del av
            försäljningen går till välgörenhet. Blommorna som vi odlar är
            solrosor och snittblommor. Vi driver även en facebooksida och ett
            instagramkonto som heter Storakungensblommor där vi lägger upp
            bilder och information om blomförsäljningen.
          </p>
        </div>
      </div>
    </>
  );
}

export default FlowerTextComponent;
